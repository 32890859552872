import './FormSuccess.css';
import React from "react";


function FormSuccess(props) {
    return (
        <div className={props.isMobile ? "successWindowMobile" : "successWindow"}>
            <button className="xButton" onClick={() => props.setShowSuccessMessage(false)}> x </button>
            <p class="discountText"> Thanks for Subscribing! </p>
        </div>
    )
}

export default FormSuccess;