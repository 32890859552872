import './App.css';
import React, { Component } from "react";
import Body from "./pages/Body.js"

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
Amplify.configure(amplifyconfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    const appClass = isMobile ? 'AppMobile' : 'App';
    return (
      <div className={appClass}>
        <Body isMobile={isMobile}/>
      </div>
      );
    }
  }


export default App;
