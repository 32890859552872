import './WinnerHighlight.css';
import React from "react";

function WinnerHighlight() {

    return (
        <div class="winnerHighlight">
        </div>
    )
}

export default WinnerHighlight;