import './Footer.css';
import React from "react";

function Footer() {

    return (
        <div class="footer">
        </div>
    )
}

export default Footer;