import './Header.css';
import React from "react";

function Header(props) {

    return (
        <div class="header">
        <div className="navBar">
            <button class={(props.activeTab === "home") ? "tab activeTab" : "tab"} onClick={() => props.setActiveTab("home")}> | past winners </button>
            <button class={(props.activeTab === "case studies") ? "tab activeTab" : "tab"} onClick={() => props.setActiveTab("case studies")}> | about </button>
            <button class={(props.activeTab === "pricing") ? "tab activeTab" : "tab"} onClick={() => props.setActiveTab("pricing")}>| apply |</button>
        </div>
        </div>
    )
}

export default Header;