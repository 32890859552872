import './Body.css';
import React, {useState} from "react";
import ftf from "../assets/images/ftf.png";
import waterBall from "../assets/images/waterBall.png";
import Footer from "./Footer.js"
import Header from "./Header.js"
// import EmailForm from "./email/EmailForm.js"
import WinnerHighlight from "./WinnerHighlight.js"
import AboutBlurb from "./AboutBlurb.js"

function Body(props) {
    const [activeTab, setActiveTab] = useState("home")

    return (
        <div class="body">
            <Header isMobile={props.isMobile} activeTab={activeTab} setActiveTab={setActiveTab}/>
            <img className="waterBall waterLeft" src={waterBall} alt="3d ball of water"></img>
            <img className="Logo" src={ftf} alt="bubble white chrome text reading Future Tech Founders, in all caps"></img>
            <img className="waterBall waterRight" src={waterBall} alt="3d ball of water"></img>
            <p className="subheaderText"> accelerator academy</p>
            <AboutBlurb isMobile = {props.isMobile}></AboutBlurb>
            <WinnerHighlight isMobile = {props.isMobile}></WinnerHighlight>
          {/* <EmailForm isMobile = {isMobile}></EmailForm> */}
            <Footer></Footer>
        </div>
    )
}

export default Body;